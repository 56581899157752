const heading = {
  fontFamily: "heading",
  fontWeight: "heading",
  lineHeight: "heading",
  mt: 0,
  mb: 3,
  letterSpacing: "-0.01em",
  ":not(:first-child)": {
    mt: 5,
  },
}

export default {
  breakpoints: ["640px", "832px", "1024px", "1280px"],
  colors: {
    text: "#202020",
    white: "#ffffff",
    primary: "#73ABC6",
    primaryDark: "#4289ad",
    blueLight: "#DCEAF1",
    background: "#ffffff",
    danger: "#D0021B",
  },
  fonts: {
    body: "bradleysdigital, sans-serif",
    heading: "bradleysdigital, sans-serif",
    display: "bradleysdigital, sans-serif",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 56, 72],
  fontWeights: {
    heading: "bold",
  },
  letterSpacings: {
    body: "normal",
    caps: "0.2em",
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  shadows: {
    focus: "0 0 0 3px #DCEAF1",
  },
  forms: {
    textarea: {
      fontFamily: "body",
      bg: "white",
      borderRadius: 0,
      ":focus": { borderColor: "primaryDark", outline: 0 },
    },
    input: {
      fontFamily: "body",
      bg: "white",
      borderRadius: 0,
      ":focus": { borderColor: "primaryDark", outline: 0 },
    },
  },
  buttons: {
    primary: {
      bg: "primary",
      color: "white",
      fontWeight: "bold",
      fontFamily: "body",
      borderRadius: 2,
      cursor: "pointer",
      ":hover": {
        bg: "primaryDark",
      },
      ":disabled": {
        bg: "blueLight",
        cursor: "not-allowed",
      },
    },
    plain: {
      bg: "transparent",
      borderRadius: 0,
      cursor: "pointer",
      px: 0,
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      color: "text",
      fontSize: [2, 3],
    },
    h1: {
      ...heading,
      fontSize: [5, 6, 7],
    },
    h2: {
      ...heading,
      fontSize: [4, 5, 6],
    },
    h3: {
      ...heading,
      fontSize: [3, 4, 5],
    },
    h4: {
      ...heading,
      fontSize: [2, 3, 4],
    },
    p: {
      mt: 0,
      mb: "1em",
    },
    ul: {
      mt: 0,
      mb: "1.5em",
    },
    a: {
      color: "primaryDark",
      textDecoration: "none",
    },
    img: {
      maxWidth: "100%",
    },
    hr: {
      border: "none",
      borderTopStyle: "solid",
      borderTopColor: "blueLight",
      borderTopWidth: 3,
      my: 4,
    },
    table: {
      width: "100%",
      border: "none",
      fontSize: [2, 3],
    },
    th: {
      textAlign: "left",
    },
    blockquote: {
      quotes: "'“' '”' '‘' '’'",
      position: "relative",
      m: 4,
      px: 2,
      "::before, ::after": {
        position: "absolute",
        left: -4,
        top: -4,
        fontSize: [6, null, 7],
        color: "primary",
      },
      "::before": {
        content: "open-quote",
      },
      "::after": {
        top: "auto",
        left: "auto",
        bottom: -4,
        right: 0,
        content: "close-quote",
      },
    },
  },
}
